import * as React from "react";
import { useState } from 'react';
import { useLocation } from "react-router-dom";
import { Notifications } from "@mui/icons-material";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";

import MenuSpeedDial from '../../../atoms/SpeedDial/SpeedDial';

// Multi Select
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

// Badge
import { Link } from "react-router-dom";
import Router from "../../../../router";

import {
  CustomTableCell,
  ContainerCell,
  FigureHead,
  SectionInfo,
  InfoCell,
  Label,
  InputDate,
  Cell,
  IconAction,
  CleanIcon,
  NewIcon,
  CustomIconButton,
  TableContent,

  CustomFormControl,
  CustomInputLabel,
  CustomBadge,
  CustomSelect,
  CustomMenuItem,

  ContainerInputsDate,
  ContainerGroup,
  ContainerSearch,

  CustomToolbar,
  ContainerGroupSelects,
  ContainerFiltros,
  ContainerDates,

  ContainerActions,
  ContentCell,
  CustomCheckIcon,

  ContainerSwitch,
  LabelSwitch,
  CustomSwitch,
  CustomTableContainer,
  ContainerDiv,
  ContainerItem,
} from "./styles";

import { StickyColumn, CustomCell, ContainerSpeedDial } from "../../../../styles/GlobalStyle";

//Utils
import { ColorsContext, useColors } from "../../../../Context/ColorsContext"
import { FormatDate } from "../../../../utils/formatDate";
import { Icon, InputAdornment, ListSubheader, TextField, Tooltip } from "@mui/material";
import { getIcon } from "../../../../utils/iconConfig";
import { snakeToCamel } from '../../../../utils/commonFunctions/convert';

// Icons
import MapIcon from "@mui/icons-material/Map";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import { ContentCopy, Delete, Search } from "@mui/icons-material";
import { Forms, ClearIconButton, SearchButton } from "../../../../pages/styles";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CustomMenu from "../../menuItem";

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from "../../../../Context/PermissionsContext";
import { TableToolbarWorkflow } from "./TableToolbarWorkflow";
import { TableToolbarMyWork } from "./TableToolbarMyWork";


function getCheck(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { colors } = useColors()
  if (props === true)
    return (
      <CheckCircleOutlineOutlinedIcon
        sx={{ color: colors.grayFont }}
        fontSize="medium"
      />
    );
}

//MUI code
function descendingComparator(a, b, orderBy, customSort) {
  let cond1 = b[orderBy] < a[orderBy]
  let cond2 = b[orderBy] > a[orderBy]

  if (customSort) {
    cond1 = eval(`b.${customSort}`) < eval(`a.${customSort}`)
    cond2 = eval(`b.${customSort}`) > eval(`a.${customSort}`)
    if (eval(`a.${customSort}`) === null) return 1
    if (eval(`b.${customSort}`) === null) return -1
  }

  if (cond1) {
    return -1;
  }
  if (cond2) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy, customSort) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, customSort)
    : (a, b) => -descendingComparator(a, b, orderBy, customSort);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function TableHeader(props) {
  const { t } = useTranslation();
  const { headCell, order, orderBy, onRequestSort, pageRef, loadData, isCustom } = props;

  const createSortHandler = (property, sortNamePaginated) => (event) => {
    let newOrder = 'asc'; // Valor inicial del orden (primer clic debería ser ascendente)

    if (orderBy === property && order === 'asc') {
      newOrder = 'desc'; // Si ya es ascendente, cambiar a descendente
    } else if (orderBy === property && order === 'desc') {
      newOrder = 'asc'; // Si ya es descendente, cambiar a ascendente
    }

    if (isCustom) {
      pageRef.current.order = sortNamePaginated;
      pageRef.current.sort = newOrder.toUpperCase(); // Actualizar el estado de order en isCustom
    }
    onRequestSort(event, property);

    if (isCustom) loadData({});
  };

  //render column UpdateAt "active"
  const activeColumn = (updatedAt) => {
    let active =  false

    if(updatedAt){
      active = true
    }
    if(orderBy.length > 0){
      active = false
    }
    return active
  }

  return (
    <CustomCell
      key={headCell.id}
      align={headCell.center ? "center" : "left"}
      padding={headCell.disablePadding ? "none" : "normal"}
      sortDirection={orderBy === headCell.id ? order : false}
      dataHead={headCell}
    >
      {headCell.sortArrow == true && (
        <TableSortLabel
          active={orderBy === headCell.id || activeColumn(headCell.orderDesc)}
          direction={orderBy === headCell.id ? order : (headCell.orderDesc ? 'desc' : "asc")}
          onClick={createSortHandler(headCell.id, headCell.sortNamePaginated)}
        >
          {t(headCell.label) || headCell.label}
          {orderBy === headCell.id ? <Box component="span"></Box> : null}
        </TableSortLabel>
      )}
      {headCell.sortArrow == false && (
        <Box component="span">{t(headCell.label) || headCell.label}</Box>
      )}
    </CustomCell>
  );
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, relations, workflow, headers, formColumns, pageRef, loadData, isCustom, isWorkflow } = props;
  const { colors } = React.useContext(ColorsContext);

  React.useEffect(() => { }, [relations, formColumns]);

  const createSortHandler = (property, customSort) => (event) => {
    onRequestSort(event, property, customSort);
  };

  return (
    <TableHead id="table head">
      <TableRow>
        {headers.map((headCell) => (
          <React.Fragment key={headCell.id}>
            {headCell.id === "relations" ? (
              <React.Fragment>
                {headCell.id === "relations" ?
                  relations?.map((headOptional) => (
                    <CustomTableCell
                      key={"optional-" + headOptional.id}
                      align={"center"}
                      padding={"none"}
                      sx={{
                        padding: "0 16px",
                        color: colors.buttonActionColor,
                        "&:hover": {
                          color: colors.buttonActionColor,
                          opacity: 0.9
                        },
                      }}
                    >
                      <FigureHead>
                        <Icon sx={{ color: colors.buttonActionColor }} fontSize="small">
                          {headOptional.icon}
                        </Icon>
                      </FigureHead>
                    </CustomTableCell>
                  ))
                  :
                  formColumns?.map((headOptional) => (
                    <CustomTableCell
                      key={"optional-" + headOptional.id}
                      align={"center"}
                      padding={"none"}
                      sx={{
                        padding: "0 16px",
                        color: colors.buttonActionColor,
                        "&:hover": {
                          color: colors.buttonActionColor,
                          opacity: 0.9
                        },
                      }}
                    >
                      {headCell.sortArrow === true && (
                        <TableSortLabel
                          active={orderBy === headOptional.id}
                          direction={orderBy === headOptional.id ? order.current : "asc"}
                          onClick={createSortHandler(headOptional.id, headCell.customSort)}
                        >
                          {/* {t(headCell.label) || headCell.label} */}
                          {headOptional['title']}
                          {orderBy === headOptional.id ? <Box component="span"></Box> : null}
                        </TableSortLabel>
                      )}
                    </CustomTableCell>
                  ))
                }
              </React.Fragment>
            ) : (
              <>
                {headCell.isOptional ? (
                  <>
                    {workflow?.[headCell.id] && (
                      <TableHeader
                        headCell={headCell}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e, value) => onRequestSort(e, value, headCell.customSort)}
                        pageRef={pageRef}
                        loadData={loadData}
                        isCustom={isCustom}
                      />
                    )}
                  </>
                ) : (
                  <TableHeader
                    headCell={headCell}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e, value) => onRequestSort(e, value, headCell.customSort)}
                    pageRef={pageRef}
                    loadData={loadData}
                    isCustom={isCustom}
                  />
                )}
              </>
            )}
          </React.Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function TableWorkflow({
  standardsSelects,
  filterStatus,
  filterPlants,
  filterCompany,
  setFilteredRequest,
  selectedTags,
  setSelectedTags,
  selectedPlants,
  setSelectedPlants,
  filteredRequest,
  rows,
  loading,
  initialDate,
  setInitialDate,
  setFinalDate,
  finalDate,
  handleFilters,
  handleClearDate,
  handleCreateWorkflow,
  handleOpenStatus,
  handleApprove,
  handleOpenDuplicate,
  handleOpenDelete,
  workflow,
  highlightLast,
  relations,
  openPlotPlan,
  headers,
  formColumns,
  setMaintainFilter,
  reloadFilters,
  hideDatesFilter,
  search,
  defaultSort,
  validityTooltip,
  hideFlowBtn,
  hideBtnCancel,
  addDataSpeedDial,
  canceledState,
  closedState,
	rowsCount,
	currentPage,
  pageSize,
  isCustom,
	onChangePage,
  handleChangeSwitch,
  handleChangeFilters,
  handleClearFilters,
  filters,
  setOpenFilterWorkflowModal,
  myWork,
  section,
  pageRef,
  loadData,
}) {
  const { t } = useTranslation();
  const { format } = new FormatDate();
  const { colors } = React.useContext(ColorsContext);
  const ability = useAbility(AbilityContext);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [customSort, setCustomSort] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [callFilter, setCallFilter] = React.useState(false);

  React.useEffect(() => {
    setPage(0);
    if (isCustom) {
      setRowsPerPage(pageSize)
    }
  }, [workflow?.id, headers]);

  React.useEffect(() => {
    if (isCustom) {
      setPage(currentPage);
    }
    else {
      setPage(0);
    }
  }, [filteredRequest]);

  React.useEffect(() => { }, [relations, formColumns]);

  React.useEffect(() => {
    if (defaultSort) {
      handleRequestSort(null, defaultSort, null, 'desc')
    }
  }, [defaultSort]);

  const handleRequestSort = (event, property, property2, defaultOrder) => {
    const isAsc = orderBy === property && order === "asc";
    if (defaultOrder) {
      setOrder(defaultOrder);
    }
    else {
      setOrder(isAsc ? "desc" : "asc");
    }
    setOrderBy(property);
    setCustomSort(property2);
  };

  const handleChangePage = async(_, newPage) => {
    if (isCustom) {
      await onChangePage(newPage, rowsPerPage)
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async(event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    if (isCustom) {
      await onChangePage(0, event.target.value);
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const fastApproveInstance = (row) => {
    if(section === 'workflow') return row.workflowStatus.canFastApproval
    if(section === 'myWork') return row.workflowData.workflowStatus.canFastApproval
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        {isCustom ?
          <TableToolbarWorkflow
            standardsSelects={standardsSelects}
            filterStatus={filterStatus}
            filterPlants={filterPlants}
            filterCompany={filterCompany}
            hideFlowBtn={hideFlowBtn}
            handleChangeFilters={handleChangeFilters}
            handleFilters={handleFilters}
            filters={filters}
            handleClearFilters={handleClearFilters}
            handleCreateWorkflow={handleCreateWorkflow}
            initialDate={initialDate}
            finalDate={finalDate}
            setOpenFilterWorkflowModal={setOpenFilterWorkflowModal}
            workflow={workflow}
          />
        :
          <TableToolbarMyWork
            standardsSelects={standardsSelects}
            filterStatus={filterStatus}
            filterPlants={filterPlants}
            rows={rows}
            filteredRequest={filteredRequest}
            setFilteredRequest={setFilteredRequest}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            selectedPlants={selectedPlants}
            setSelectedPlants={setSelectedPlants}
            initialDate={initialDate}
            finalDate={finalDate}
            setInitialDate={setInitialDate}
            setFinalDate={setFinalDate}
            handleFilters={handleFilters}
            handleChangeFilters={handleChangeFilters}
            filters={filters}
            handleClearDate={handleClearDate}
            handleCreateWorkflow={handleCreateWorkflow}
            workflow={workflow}
            setMaintainFilter={setMaintainFilter}
            reloadFilters={reloadFilters}
            hideDatesFilter={hideDatesFilter}
            search={search}
            hideFlowBtn={hideFlowBtn}
            canceledState={canceledState}
            closedState={closedState}
            handleChangeSwitch={handleChangeSwitch}
            rowsPerPage={rowsPerPage}
            rowsCount={rowsCount}
            isCustom={isCustom}
            callFilter={callFilter}
            page={page}
          />
        }
        {!loading && (
          <React.Fragment>
            <CustomTableContainer isCustom={isCustom}>
              <Table stickyHeader
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  relations={relations}
                  workflow={workflow}
                  headers={headers}
                  formColumns={formColumns}
                  pageRef={pageRef}
                  isCustom={isCustom}
                  loadData={loadData}
                />
                <TableContent>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                    rows.sort(getComparator(order, orderBy)).slice() */}
                  {stableSort(filteredRequest, getComparator(order, orderBy, customSort))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          sx={{
                            background:
                              index === 0 && highlightLast
                                ? "#c3e6fe"
                                : " none",
                            transition: "2s",
                            verticalAlign: 'super',
                          }}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          <StickyColumn direction="left">
                            <ContainerDiv myWork={myWork}>
                              {myWork &&
                                <ContainerItem show={row.isNotified}>
                                    {row.isNotified &&
                                      <Notifications color='warning'/>
                                    }
                                </ContainerItem>
                              }
                              <ContainerItem>
                                <Link
                                  to={Router.appWorkflowDetail
                                    .replace(":workflowId", row.workflowId)
                                    .replace(":id", row.workflowData?.id || row.id)}
                                  state={{ row: row, workflow: workflow }}
                                >
                                  <span style={{ color: colors.detailTextColor }}>
                                    {row.code !== null ? row.code : row.id}
                                  </span>
                                </Link>
                              </ContainerItem>
                            </ContainerDiv>
                          </StickyColumn>

                          <Cell sx={{ minWidth: 250 }}>
                            <ContainerCell>
                              {getIcon(row.workflowStatus)}
                              <SectionInfo>
                                <InfoCell>
                                  <p style={{ fontSize: '0.875rem', color: colors.grayFont }}>
                                    {(row.workflowStatus?.id >= 0 && t(`workflowManagement.flowState.status.${snakeToCamel(row.workflowStatus?.type)}`)) || ""}
                                  </p>
                                </InfoCell>
                                <InfoCell>
                                  {row.workflowStatus?.title || ""}
                                </InfoCell>
                              </SectionInfo>
                            </ContainerCell>
                          </Cell>

                          {workflow?.hasTitle && <Cell sx={{ minWidth: 200 }}>{row.title || ""}</Cell>}
                          <Cell sx={{ minWidth: 200 }}>{row.plant?.name}</Cell>

                          {workflow?.hasValidity && (
                            <Tooltip title={validityTooltip(row?.validityFrom, row?.validityTo)}>
                              <Cell>
                                <ContainerCell>
                                  <SectionInfo>
                                    {row?.validityFrom && (
                                      <InfoCell>
                                        <div>
                                          {format({ date: row?.validityFrom, formatWithoutHour: true })}
                                        </div>
                                      </InfoCell>
                                    )}
                                  </SectionInfo>
                                </ContainerCell>
                              </Cell>
                            </Tooltip>
                          )}

                          <Cell>
                            <ContentCell>
                              {format({ date: row?.updatedAt, format: true })}
                            </ContentCell>
                          </Cell>
                          {row?.formColumn?.map((column, index) => (
                            <React.Fragment key={"formColumn-" + index}>
                              <Cell align="center">{column['value'] ?? ''}</Cell>
                            </React.Fragment>
                          ))}
                          {((workflow?.hasPlotPlan || workflow?.hasPlotPlanNotRequired) && ability.can("PlotPlanList") && ability.can("GetPlotPlan")) && (
                            <Cell>
                              <CustomIconButton
                                size="small"
                                onClick={() => openPlotPlan(row)}
                              >
                                <MapIcon />
                              </CustomIconButton>
                            </Cell>
                          )}


                          <StickyColumn direction="right" >

                            {/* Actions component for mobile dimension  */}
                            <ContainerSpeedDial>
                              <MenuSpeedDial
                                options={addDataSpeedDial(row, workflow)}
                              />
                            </ContainerSpeedDial>

                            {/* Default render actions table */}
                            <ContainerActions>
                              <div>
                                <Tooltip title={t("common.actions.process")}>
                                  <CustomIconButton
                                    size="small"
                                    onClick={() => handleOpenStatus(row, workflow)}
                                  >
                                    <SlowMotionVideoIcon />
                                  </CustomIconButton>
                                </Tooltip>
                              </div>
                              {ability.can("CloneWorkflowData") &&
                                <div>
                                  <Tooltip title={t("common.actions.duplicate")}>
                                    <CustomIconButton
                                      size="small"
                                      onClick={() => handleOpenDuplicate(row)}
                                    >
                                      <ContentCopy />
                                    </CustomIconButton>
                                  </Tooltip>
                                </div>
                              }
                              {ability.can("DeleteWorkflowData") &&
                                <div>
                                  <Tooltip title={t("common.actions.cancel")}>
                                    <span>
                                      <CustomIconButton
                                        sx={{ display: hideBtnCancel ? 'block' : 'none' }}
                                        size="small"
                                        disabled={
                                          row.workflowStatus?.statusId === 10002 ||
                                          row.workflowStatus?.statusId === 10003 ||
                                          row.workflowData?.canCancel === false ||
                                          row?.canCancel === false
                                        }
                                        onClick={() => handleOpenDelete(row)}
                                      >
                                        <Delete />
                                      </CustomIconButton>
                                    </span>
                                  </Tooltip>
                                </div>
                              }
                              {(ability.can("ApproveWorkflowData") || ability.can("ApproveWorkflowDataCredentials")) &&
                                <div>
                                  <CustomMenu
                                    tooltip={t('workflow.table.fastApproval')}
                                    title={<CustomCheckIcon />}
                                    disabled={!row.isApprove || (fastApproveInstance(row) === false)}
                                    handleSelected={handleApprove}
                                    row={row}
                                  />
                                </div>
                              }
                            </ContainerActions>
                          </StickyColumn>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableContent>
              </Table>
            </CustomTableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rowsCount}
              rowsPerPage={rowsPerPage}
              showFirstButton={true}
              showLastButton={isCustom ? false : true}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t("common.itemsPerPage")}
              labelDisplayedRows={({ from, to, count }) => {
                return "" + from + "-" + to + ` ${t("common.of")} ` + count;
              }}
              sx={{
                borderTop: "1px solid " + colors.gray,
              }}
            />
          </React.Fragment>
        )}
      </Paper>
      {/*
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      */}
    </Box>
  );
}




