import { CardGraphic } from '../CardGraphic';

import { ContainerTopGrahps, ContainerGraphics, ContainerBottomGrahps, ContainerTreeMap } from './style';

export const GrahpsDeviationsProcess = ({
  containerViabilRef,
  containerExcavaRef,
  containerSasRef,
  containerSaesRef,
  chartRef,
  optionsGraphics,
  calculateChartWidth,
  calcDataGrahps,

  optionsDonut,
  optionsGraphicDeviation,
}) => {


  return (
    <>
      <ContainerTopGrahps>
        <ContainerGraphics width='100%' ref={containerViabilRef}>
          <CardGraphic
            width={(containerViabilRef?.current?.clientWidth) && (calculateChartWidth('CJ54JOC6', calcDataGrahps) < containerViabilRef?.current?.clientWidth) ? '100%' : calculateChartWidth('CJ54JOC6', calcDataGrahps)}
            title='viabilizacion'
            type="bar"
            {...optionsGraphics('CJ54JOC6')}
            chartRef={chartRef}
          />
        </ContainerGraphics>

        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '0.50rem' }}>
          <ContainerGraphics width='50%' ref={containerSaesRef}>
            <CardGraphic
              width={(containerSaesRef?.current?.clientWidth) && (calculateChartWidth('CB6KLBXD', calcDataGrahps) < containerSaesRef?.current?.clientWidth) ? '100%' : calculateChartWidth('CB6KLBXD', calcDataGrahps)}
              title='saes'
              type="bar"
              {...optionsGraphics('CB6KLBXD')}
              chartRef={chartRef}
            />
          </ContainerGraphics>

          <ContainerGraphics width='50%' ref={containerExcavaRef}>
            <CardGraphic
              width={(containerExcavaRef?.current?.clientWidth) && (calculateChartWidth('7V3B11QA', calcDataGrahps) < containerExcavaRef?.current?.clientWidth) ? '100%' : calculateChartWidth('7V3B11QA', calcDataGrahps)}
              title='excavacion'
              type="bar"
              {...optionsGraphics('7V3B11QA')}
              chartRef={chartRef}
            />
          </ContainerGraphics>
        </div>

      </ContainerTopGrahps>

      <ContainerBottomGrahps>
        <ContainerGraphics width='100%' ref={containerSasRef}>
          <CardGraphic
            width={(containerSasRef?.current?.clientWidth) && (calculateChartWidth('VUJBZIKW', calcDataGrahps) < containerSasRef?.current?.clientWidth) ? '100%' : calculateChartWidth('VUJBZIKW', calcDataGrahps)}
            title='sas'
            type="bar"
            {...optionsGraphics('VUJBZIKW')}
            chartRef={chartRef}
          />
        </ContainerGraphics>

        <ContainerTreeMap>
          <ContainerGraphics width='100%' height='50%'>
            <CardGraphic
              type="treemap"
              {...optionsGraphicDeviation('up')}
              chartRef={chartRef}
              height='100%'
            />
          </ContainerGraphics>
          <ContainerGraphics width='100%' height='50%'>
            <CardGraphic
              type="treemap"
              {...optionsGraphicDeviation('down')}
              chartRef={chartRef}
              height='100%'
            />
          </ContainerGraphics>
        </ContainerTreeMap>


      </ContainerBottomGrahps>
    </>
  )
}