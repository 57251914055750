import jsPDF from "jspdf";
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const downloadPDF = async(components, formRef, pdfName, setLoading, checkLandscapeOrientation) => {
    let hasAttribute = false;
    if(checkLandscapeOrientation){
        hasAttribute = checkLandscapeOrientation(components);
    }
    let orientation = 'portrait'
    if (components[0]?.attributes?.['pdf-orientation'] === 'landscape' || components?.attributes?.['pdf-orientation'] === 'landscape' || hasAttribute) {
        orientation = 'landscape'
    }

    setLoading(true)
    const doc = new jsPDF({
        orientation: orientation,
        unit: 'mm',
        format: [1361.5, 1883.5],
        compress: true
    });

    doc.setFont('Inter-Regular', 'normal');

    let options = {
        margin: 20,
        autoPaging: true,
        width: orientation === 'portrait' ? 1320 : 1850,
        windowWidth: orientation === 'portrait' ? 1320 : 2000
    }

    doc.html(formRef.current, {
        ...options,
        async callback(doc) {
            let pagesToDelete = await checkBlankPages(doc.output('datauristring'))
            pagesToDelete.reverse().map(numPage => doc.deletePage(numPage))
            setLoading(false)

            doc.save(pdfName);
        },
    });
}

async function checkBlankPages(url) {
    
    // Load the PDF page using react-pdf
    const pagePdf = pdfjs.getDocument(url);
    const pdf = await pagePdf.promise;
    const totalNumPages = Array.from({length: pdf.numPages}, (_, i) => i + 1)
    const emptyPages = [];

    // Loop through each page to check for blank content
    await Promise.all(
        totalNumPages.map(async (pageNum) => {
            try {
                const page = await pdf.getPage(pageNum);
                const textContent = await page.getTextContent();
                const pageText = textContent.items.map(item => item.str).join('');

                if (!pageText.trim()) emptyPages.push(pageNum);
                return emptyPages
    
            } catch (error) {
                console.error('Error checking page:', error);
            }
        })
    )

    return emptyPages
}