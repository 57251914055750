import React, { useEffect, useState, useRef } from 'react'

// Components
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip, Typography } from '@mui/material'
import { toast } from 'react-toastify';

// Router
import Router from '../../router';
import { useNavigate, useLocation } from 'react-router-dom';

// Custom components
import { PageTitle } from '../../components/PageTitle';
import { WorkflowApi } from '../../services/WorkflowApi';
import { SelectInput } from '../../components/SelectInput';
import { BackdropLoading } from '../../components/BackdropLoading';
import { ShowDialog } from '../../components/ShowDialog';
import { FormIo } from '../../components/molecules/forms/FormIo';
import { pdfTableStyleDetail } from '../DynamicForm/pdfTableStyleDetail';

// API
import { FormApi } from '../../services/FormApi';

// Styles
import { Container, FormButton, PdfIconButton, PdfDownloadIcon } from '../styles';
import { SectionPageTitle } from '../FormManagement/styles';
import {
  ActionButton,
  PreviewButton,
  SaveButton,
  ContainerGroup,
  ContainerInput,
  CustomTextField,
  SectionSelects,
  ContainerContent,
  ContainerSettings,
  SectionBtnLeft,
  SectionBtnRight,
  ContainerForm
} from './styles';


import './styles.css'

// Icons
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import { ArrowBack } from '@mui/icons-material';

// Utils
import { ColorsContext } from "../../Context/ColorsContext"
import { CheckResponse } from '../../utils/checkResponse';
import { useTranslation } from 'react-i18next'
import { downloadPDF } from '../../utils/commonFunctions/downloadPdf';
import { lngResources } from '../../utils/commonFunctions';
import Es from '../../utils/i18n/languages/es.json'
import En from '../../utils/i18n/languages/en.json'
import Pt from '../../utils/i18n/languages/pt.json'

// JS
import { Form, FormBuilder } from '@formio/react';

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext';

export const CreateFormioForm = (props) => {
  const { t, i18n } = useTranslation();
  const { colors } = React.useContext(ColorsContext);
  const ability = useAbility(AbilityContext);
  const translationResources = lngResources(Es, En, Pt);

  const navigate = useNavigate();
  const location = useLocation();

  const workflowApi = new WorkflowApi();
  const formApi = new FormApi();
  const { check } = new CheckResponse();

  const [workflowList, setWorkflowList] = useState([]);
  const [workflow, setWorkflow] = useState({ value: {}, error: "" });
  const [formName, setFormName] = useState({ value: "", error: "" });

  const [isPreview, setIsPreview] = useState(false);
  const [row, setRow] = useState({});
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = React.useState({
    title: '',
    message: '',
    status: false
  });
  const currentFormRef = useRef(undefined);
  const originalStylesRef = useRef("");

  const [form, setForm] = useState({})

  const fileInputRef = useRef(null);
  const formRef = useRef(null);

  function updateForm(schema) {
    setForm(schema)
  }

  function handleChange(event, name) {
    if (name === 'name') setFormName({ value: event.target.value, error: '' })
    else setWorkflow({ value: event, error: '' })
  }

  function handleCloseDialog() {
    setDialog({ ...dialog, status: false })
    if (dialog.typeResponse !== 'error') {
      navigate(Router.appFormManagementV2)
    }
  }

  function handleClosePreview() {
    setIsPreview(false)
  }

  function openPreview() {
    setIsPreview(true)
  }

  async function validateForm() {
    let errorFormName = !formName.value ? t('forms.dynamicForm.validations.formName') : ""
    let errorWorkflow = !workflow.value?.id ? t('forms.dynamicForm.validations.workflow') : ""
    let errorForm = form?.components?.length === 0 ? t('forms.dynamicForm.validations.empty') : ""

    if (errorFormName || errorWorkflow || errorForm) {
      setFormName({ ...formName, error: errorFormName })
      setWorkflow({ ...workflow, error: errorWorkflow })
      toast.error(errorForm || t('forms.dynamicForm.messages.errorsForm'))
      return
    }

    let data = {
      name: formName.value,
      workflowId: workflow.value?.id,
      structure: form.components,
      renderer: "form_io"
    }

    if (row.id) {
      await handleEdit(data)
    }
    else {
      await handleSave(data)
    }
  }

  async function handleEdit(data) {
    setLoading(true)
    let response = await formApi.update(row.id, data);
    let result = check(response)

    if (result.status) {
      setDialog({
        title: t('forms.dynamicForm.messages.updatedForm.title'),
        message: t('forms.dynamicForm.messages.updatedForm.message'),
        status: true
      })
    }
    else {
      toast.error(() =>
        <div>{t('forms.dynamicForm.messages.updatedForm.error')}<br />
          {result.errors}
        </div>
      );
    }
    setLoading(false)
  }

  async function handleSave(data) {
    setLoading(true)
    let response = await formApi.create(data);
    let result = check(response)

    if (result.status) {
      setDialog({
        title: t('forms.dynamicForm.messages.saveForm.title'),
        message: t('forms.dynamicForm.messages.saveForm.message'),
        status: true
      })
    }
    else {
      toast.error(() =>
        <div>{t('forms.dynamicForm.messages.saveForm.error')}<br />
          {result.errors}
        </div>
      );
    }

    setLoading(false)
  }

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0]; // Accede al primer archivo seleccionado

    if (file) {
      const reader = new FileReader();

      const fileName = file.name.toLowerCase();
      const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
      if (fileExtension !== 'json') {
        toast.error(t('forms.dynamicForm.messages.errorsForm'));
        return;
      }

      reader.onload = (e) => {
        const content = e.target.result; // Accede al contenido del archivo

        try {
          const parsedContent = JSON.parse(content); // Intenta analizar el contenido como JSON
          setForm(parsedContent)
        } catch (error) {
          toast.error(t('forms.dynamicForm.messages.errorsForm'));
        }
      };

      reader.readAsText(file); // Lee el archivo como texto
    }
  };

  const handleDownloadForm = () => {

    let formioData = { ...form }
    const jsonContent = JSON.stringify(formioData);

    const blob = new Blob([jsonContent], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "formio.json";
    document.body.appendChild(link);

    link.click();
  }

  useEffect(() => {
    async function call() {
      let response = await workflowApi.list();
      if (response.status === 200) {
        setWorkflowList(response.data)
      }
    }
    call()

    if (location?.state?.row?.id) {
      let row = location.state.row;
      setRow(row)
      setWorkflow({ value: row.workflow })
      setFormName({ value: row.name })

      async function callDetail() {
        setLoading(true)
        let response = await formApi.detail(row.id);
        if (response.status === 200) {
          let dataStructure = response.data?.structure?.structure
          setForm({ components: dataStructure })
        }
        setLoading(false)
      }
      callDetail()
    }
  }, [])

  const generatePdf = async () => {
    let isExecuteDownload = false;
    //Nos devuelve la ruta del elemento style
    let foundElementStyle = findElement(currentFormRef.current.component);
    // Navegar al elemento style del formulario que llega actualmente
    let route = foundElementStyle.path.join(".")
    // Se crea una copia de la instancia
    let copyFormInstance = currentFormRef.current;
    // Se ejecuta la ruta en la instancia que llega, para dirigirnos a su elemento style
    let instanceElement = eval(`copyFormInstance.${route}`);
    originalStylesRef.current = instanceElement.component.content;
    instanceElement.component.content = instanceElement.component.content + pdfTableStyleDetail();

    currentFormRef.current = copyFormInstance;
    currentFormRef.current.triggerRedraw();
    currentFormRef.current.refresh();

    setTimeout(async () => {
      if (currentFormRef?.current !== null) {
        isExecuteDownload = true;
        await downloadPDF(form.components, formRef, row.name || 'form_pdf', setLoading, checkLandscapeOrientation)
      }
      else {
        setLoading(false)
      }
    }, 1000)

    setTimeout(() => {
      if (isExecuteDownload) {
        instanceElement.component.content = originalStylesRef.current;
        currentFormRef.current = copyFormInstance;

        currentFormRef.current.triggerRedraw();
        currentFormRef.current.refresh();
      }
    }, 2000);

  }

  const findElement = (obj, path = []) => {
    if (obj?.tag === "style") {
      return { element: obj, path };
    }

    if (obj.components) {
      for (let i = 0; i < obj.components.length; i++) {
        const component = obj.components[i];
        const result = findElement(component, [...path, `components[${i}]`]);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }

  function checkLandscapeOrientation(obj) {
    if (Array.isArray(obj.components)) {
      for (let componentObj of obj.components) {
        if (componentObj.component && typeof componentObj.component === 'object') {
          const attributes = componentObj.component.attributes;
          if (attributes && typeof attributes === 'object') {
            if (attributes['pdf-orientation'] === 'landscape') {
              return true;
            }
          }
        }
        if (checkLandscapeOrientation(componentObj)) {
          return true;
        }
      }
    }
    return false;
  }

  return (
    <Container open={props.open} mb={6}>
      <SectionPageTitle>
        <PageTitle title="" isbutton={true} navigate={Router.appFormManagementV2} icon={<ArrowBack />} />
        <PageTitle title={row.name ? `${t('forms.dynamicForm.editForm')} ${row.id}` : t('forms.dynamicForm.createForm')} icon={<ViewComfyIcon />} />
      </SectionPageTitle>

      <ContainerForm>
        <SectionSelects>
          <ContainerGroup>
            <ContainerInput>
              <CustomTextField
                label={t('forms.dynamicForm.formName')}
                onChange={e => handleChange(e, 'name')}
                value={formName.value}
                error={!(!formName.error)}
                helperText={formName.error}
                size='small'
              />
            </ContainerInput>

            {ability.can("WorkflowList") &&
              <ContainerInput>
                <SelectInput
                  label={t('forms.dynamicForm.selectWorkflow')}
                  onChange={e => handleChange(e, 'workflowId')}
                  value={workflow.value}
                  keyValue="name"
                  options={workflowList}
                  error={workflow.error}
                  width='100%'
                  size='small'
                />
              </ContainerInput>
            }
          </ContainerGroup>
        </SectionSelects>

        <BackdropLoading open={loading} />
        <ShowDialog openDialog={dialog.status} dialog={dialog} handleCloseDialog={handleCloseDialog} />

        <ContainerContent>
          <ContainerSettings id="Container Settings">

            <SectionBtnLeft>
              <input type="file" accept=".json" onChange={handleFileUpload} style={{ display: 'none' }} ref={fileInputRef} />
              <ActionButton variant="contained" onClick={() => setForm({ components: [] })}>
                {t('forms.dynamicForm.clean')}
              </ActionButton>

              <ActionButton variant="contained" onClick={handleUploadClick}>
                {t('forms.dynamicForm.load')}
              </ActionButton>

              <ActionButton variant="contained" onClick={handleDownloadForm}>
                {t('common.download')}
              </ActionButton>

              <PreviewButton variant="contained" onClick={() => openPreview()}>
                {t('forms.dynamicForm.preview')}
              </PreviewButton>
            </SectionBtnLeft>

            <SectionBtnRight>
              <SaveButton variant="contained" onClick={() => validateForm()}>
                {t('forms.dynamicForm.saveForm')}
              </SaveButton>
            </SectionBtnRight>

          </ContainerSettings>

          <FormBuilder
            form={form}
            onChange={(schema) => updateForm(schema)}
            options={{
              noDefaultSubmitButton: true,
              language: i18n.language,
              i18n: translationResources,
            }}
          />
        </ContainerContent>
      </ContainerForm>

      <Dialog open={isPreview} onClose={handleClosePreview} maxWidth="lg" fullWidth={true} sx={{ minHeight: 500 }}>
        <DialogTitle>
          <Grid container>
            <Grid item xs={10}>
              <Typography variant='h6' sx={{ color: colors.detailTextColor }}>{t('forms.dynamicForm.formPreview')}</Typography>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: 'right' }}>
              <Tooltip title={t("common.downloadPdf")}>
                {/* <PdfIconButton onClick={generatePdf}/> */}
                <PdfDownloadIcon onClick={generatePdf}/>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <div ref={formRef}>
            <Form
              form={{ components: form.components }}
              options={{
                noDefaultSubmitButton: true,
                language: i18n.language,
                i18n: translationResources,
              }}
              formReady={(instance) => {
                if (instance !== undefined) {
                  currentFormRef.current = instance;
                }
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <FormButton onClick={handleClosePreview}>{t('common.close')}</FormButton>
        </DialogActions>
      </Dialog>

    </Container>
  )
}