import React from 'react'

// Components
import { TextField } from '@mui/material'

// Styles
import { Label, ContainerInput, ContainerGroup, ContainerContent, ContainerBox, ContainerChecks, CustomCheckbox } from './styles'

// Utils
import { useTranslation } from 'react-i18next'

export const CompanyWorkerForms = ({
    form, errors, disabled, handleChange, handleChangeCertificates
}) => {
    const { t } = useTranslation();

    return (
        <ContainerBox>
            <ContainerContent>
                <ContainerGroup>
                    <ContainerInput>
                        <Label>{t('worker.table.identification')}:</Label>
                        <TextField
                            type='number'
                            value={form.identification}
                            size="small"
                            onChange={e => handleChange(e, 'identification')}
                            error={!(!errors?.identification.error)}
                            helperText={errors?.identification.error}
                            style={{ width: '100%' }}
                            disabled={disabled}
                            required
                        />
                    </ContainerInput>
                </ContainerGroup>

                <ContainerGroup>
                    <ContainerInput>
                        <Label>{t('worker.table.name')}:</Label>
                        <TextField
                            value={form.name}
                            size="small"
                            onChange={e => handleChange(e, 'name')}
                            error={!(!errors?.name.error)}
                            helperText={errors?.name.error}
                            style={{ width: '100%' }}
                            disabled={disabled}
                            required
                        />
                    </ContainerInput>
                    <ContainerInput>
                        <Label>{t('worker.table.lastName')}:</Label>
                        <TextField
                            value={form.lastName}
                            size="small"
                            onChange={e => handleChange(e, 'lastName')}
                            error={!(!errors?.lastName.error)}
                            helperText={errors?.lastName.error}
                            style={{ width: '100%' }}
                            disabled={disabled}
                            required
                        />
                    </ContainerInput>
                </ContainerGroup>

                <ContainerGroup>
                    <ContainerInput>
                        <Label>{t('worker.table.eps')}:</Label>
                        <TextField
                            value={form.eps}
                            size="small"
                            onChange={e => handleChange(e, 'eps')}
                            error={!(!errors?.eps.error)}
                            helperText={errors?.eps.error}
                            style={{ width: '100%' }}
                            disabled={disabled}
                            required
                        />
                    </ContainerInput>
                    <ContainerInput>
                        <Label>{t('worker.table.arl')}:</Label>
                        <TextField
                            value={form.arl}
                            size="small"
                            onChange={e => handleChange(e, 'arl')}
                            error={!(!errors?.arl.error)}
                            helperText={errors?.arl.error}
                            style={{ width: '100%' }}
                            disabled={disabled}
                            required
                        />
                    </ContainerInput>
                </ContainerGroup>

                <ContainerGroup>
                    <ContainerInput>
                        <Label>{t('worker.table.emergencyContactFullName')}:</Label>
                        <TextField
                            value={form.emergencyContactFullName}
                            size="small"
                            onChange={e => handleChange(e, 'emergencyContactFullName')}
                            error={!(!errors?.emergencyContactFullName.error)}
                            helperText={errors?.emergencyContactFullName.error}
                            style={{ width: '100%' }}
                            disabled={disabled}
                            required
                        />
                    </ContainerInput>
                    <ContainerInput>
                        <Label>{t('worker.table.emergencyContactPhone')}:</Label>
                        <TextField
                            value={form.emergencyContactPhone}
                            size="small"
                            onChange={e => handleChange(e, 'emergencyContactPhone')}
                            error={!(!errors?.emergencyContactPhone.error)}
                            helperText={errors?.emergencyContactPhone.error}
                            style={{ width: '100%' }}
                            disabled={disabled}
                            required
                        />
                    </ContainerInput>
                </ContainerGroup>

                <ContainerGroup>
                    <ContainerChecks>
                        {form.certificates.map((value) => (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CustomCheckbox onClick={() => handleChangeCertificates(value.id)} checked={value.status} />
                                <Label sx={{ fontWeight: 'normal', fontSize: '1.2rem' }}>{value?.name ?? ""}</Label>
                            </div>
                        ))}
                    </ContainerChecks>
                </ContainerGroup>

            </ContainerContent>
        </ContainerBox>
    )
}