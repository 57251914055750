import { Auth } from "../utils/auth";
import { HealthCheckApi } from "./HealthCheckApi";
import Router from "../router";
import axios from "./AxiosConfig";

export class UserApi {
    healthCheck = null;
    token = null;

    constructor() {
        let auth = new Auth();
        this.token = auth.getToken();
        this.healthCheck = new HealthCheckApi();
    }

    async list(isEnabled, plantId, some = false) {
        let auth_token = { 'Authorization': `Bearer ${this.token}`}

        let url = `${Router.apiBaseUrl}${Router.apiUser}/${some ? 'some' : 'all'}`;

        return await axios.get(url, {
            headers: auth_token,
            params: {
                isEnabled: isEnabled,
                plantId: plantId
            }
        })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async paginated({sort, order, isEnabled, roleId, pageSize, currentPage, filter}) {
        let url = `${Router.apiBaseUrl}${Router.apiUserPaginated}`;

        return await axios.get(url, {
            params: {
                sort: sort,
                order: order,
                isEnabled: isEnabled,
                roleId: roleId,
                pageSize: pageSize,
                currentPage: currentPage,
                filter: filter
            }
        })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async detail(filter, checkAD) {
        return await axios.get(`${Router.apiBaseUrl}${Router.apiGetUserFilter.replace(':filter', filter)}`, {
            headers: { 'Authorization': `Bearer ${this.token}`},
            params: {
                checkAD: checkAD
            }
        })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)

        })
    }

    async update(id, user) {
        let auth_token = { 'Authorization': `Bearer ${this.token}`}

        return await axios.put(
            `${Router.apiBaseUrl}${Router.apiUser}/update/${id}`,
            {...user},
            { headers: auth_token }
        )
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async toggleAccount(id, user) {
        let auth_token = { 'Authorization': `Bearer ${this.token}`}

        return await axios.patch(
            `${Router.apiBaseUrl}${Router.apiUser}/toggleState/${id}`,
            {...user},
            { headers: auth_token }
        )
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async updatePassword(user) {
        let auth_token = { 'Authorization': `Bearer ${this.token}`}

        return await axios.patch(
            `${Router.apiBaseUrl}${Router.apiUser}/updatePassword`,
            {...user},
            { headers: auth_token }
        )
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async forgotPassword(form, url) {
        let auth_token = { 'Authorization': `Bearer ${this.token}`}

        return await axios.post(
            `${Router.apiBaseUrl}${Router.apiUser}/forgotPassword`,
            { username: form.username, url, reCaptchaToken: form.reCaptchaToken },
            { headers: auth_token }
        )
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async resetPassword(data) {
        let auth_token = { 'Authorization': `Bearer ${this.token}`}

        return await axios.post(
            `${Router.apiBaseUrl}${Router.apiUser}/resetPassword`,
            { ...data },
            { headers: auth_token }
        )
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async upload(data, onlyCheckFiles) {
        return await axios.post(Router.apiBaseUrl + Router.apiUserAttachment, data, {
            params: {
                onlyCheckFiles: onlyCheckFiles
            }
        })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async getFile(id) {
        return await axios.get(`${Router.apiBaseUrl}${Router.apiUserAttachment}/all/${id}`, {})
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async deleteFile(id) {
        return await axios.delete(`${Router.apiBaseUrl}${Router.apiUserAttachment}/${id}`, {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async download(filter) {
        return await axios.get(`${Router.apiBaseUrl}${Router.apiUserDownload}`, {
            params: {
                filter: filter
            },
            responseType: 'blob'
        })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            let responseObj = await error?.response?.data?.text();
            let errorResponse = {response: JSON.parse(responseObj)}
            return await this.healthCheck.checkApi(errorResponse)
        })
    }
}