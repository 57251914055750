import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { CompanyWorkerForms } from '../../../molecules/forms/CompanyWorkerForms';

// Custom components
import { BackdropLoading } from '../../../BackdropLoading';

// Styles
import { Container, Header, Body, Footer, SectionRight, Spacer, } from './styles'
import { FormButton } from '../../../../pages/styles';

// Utils
import { validateForm } from '../../../../utils/commonFunctions';

export const CompanyWorkerModalCard = ({ data, dataCertificates, handleSave, handleClose }) => {

    const { t } = useTranslation();

    const [form, setForm] = useState({
        identification: '',
        name: '',
        lastName: '',
        eps: '',
        arl: '',
        emergencyContactFullName: '',
        emergencyContactPhone: '',
        certificates: dataCertificates
    })
    const [errors, setErrors] = useState({
        identification: {error: '', field: t('worker.table.identification'), required: true},
        name: {error: '', field: t('worker.table.name'), required: true},
        lastName: {error: '', field: t('worker.table.lastName'), required: true},
        eps: {error: '', field: t('worker.table.eps'), required: false},
        arl: {error: '', field: t('worker.table.arl'), required: false},
        emergencyContactFullName: {error: '', field: t('worker.table.emergencyContactFullName'), required: false},
        emergencyContactPhone: {error: '', field: t('worker.table.emergencyContactPhone'), required: false},
    })
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        async function call() {
            setLoading(true)

            if (data?.workerId) {
                setForm({...form,
                    id: data.workerId,
                    identification: data.identification,
                    name: data.name,
                    lastName: data.lastName,
                    eps: data.eps,
                    emergencyContactFullName: data.emergencyContactFullName,
                    emergencyContactPhone: data.emergencyContactPhone,
                    arl: data.arl,
                    companyId: data.companyId,
                    certificates: data?.certificates ?? []
                })
            }
            setLoading(false)
        }
        call()
    }, [])

    function handleChange(event, name, type) {
        setForm({...form, [name]: event.target.value})
        setErrors({...errors, [name]: {...errors[name], error: ''}})
    }

    function handleChangeCertificates(id) {
        setForm(prevForm => ({
            ...prevForm,
            certificates: prevForm.certificates.map(cert => 
                cert.id === id ? { ...cert, status: !cert.status } : cert
            )
        }));
    }

    function checkForm() {
        let validate = validateForm(form, errors)
        if (validate.hasErrors) {
            setErrors(validate.errors)
            return
        }
        handleSave(form)
    }

    return (
        <Container>
            <Header>{data?.id ? t('worker.modal.edit') : t('worker.modal.create')}</Header>
            <Body>
                <CompanyWorkerForms 
                    form={form}
                    errors={errors}
                    disabled={disabled}
                    handleChange={handleChange}
                    handleChangeCertificates={handleChangeCertificates}
                />
            </Body>
            <Footer>
                <SectionRight>
                    <FormButton 
                        variant="outlined" 
                        type="close" 
                        onClick={() => handleClose(false)}
                    >
                        {t('common.cancel')}
                    </FormButton>
                    <Spacer/>
                    <FormButton
                        variant="contained"
                        onClick={checkForm}
                    >
                        {t('common.save')}
                    </FormButton>
                </SectionRight>
            </Footer>
            <BackdropLoading open={loading}/>
        </Container>
    )
}