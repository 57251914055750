import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import { Icon, Modal, Button, Tooltip } from "@mui/material";
import { CloudDownloadOutlined } from "@mui/icons-material";

import { PageTitle } from "../../components/PageTitle";
import { SearchBarWorkflow } from "../../components/atoms/searchBars/SearchBarWorkflow";
import TableWorkflow from "../../components/atoms/tables/TableWorkflow";

// icons
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import { ContentCopy, Delete } from "@mui/icons-material";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
  BoxModal,
  Container,
  ContainerBarSearch,
  ContainerButtonDownload,
  SectionPageLeft,
  SectionPageTitle,
  ContainersButtonsGroup,
} from "../styles";

import { Spacer, NewIcon, IconAction } from "./styles";

// Components
import { toast } from "react-toastify";
import { InstanceStateModalCard } from "../../components/atoms/modalCards/InstanceStateModalCard";
import { PlotPlanView } from "../../components/PlotPlanView";
import { BackdropLoading } from "../../components/BackdropLoading";
import { ShowDialog } from "../../components/ShowDialog";
import { FilterWorkflowsModalCard } from "../../components/atoms/modalCards/FiltersWorkflowModalCard";

//Services
import { WorkflowApi } from "../../services/WorkflowApi";
import { WorkflowDataApi } from "../../services/WorkflowDataApi";
import { WorkflowStatusApi } from "../../services/WorkflowStatusApi";
import { PlantApi } from "../../services/PlantApi";
import { DashboardReportApi } from "../../services/DashboardReportApi";

// Utils
import { CheckResponse } from "../../utils/checkResponse";
import {
  findNotificationTable,
  getNotifications,
  redirectToAction,
  returnInstanceNotifications,
} from "../../utils/commonFunctions";
import { useTranslation } from "react-i18next";
import { FormatDate } from "../../utils/formatDate";

import { ExportExcel } from "../../utils/exportExcel";
import { ColorsContext } from "../../Context/ColorsContext";
import { InstanceNotificationsModal } from "../../components/atoms/modalCards/InstanceNotificationsModal";

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext'

export const Workflow = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { format } = new FormatDate();
  const pageRef = React.useRef({
    order: null,
    sort: null,
    page: 0,
    pageSize: 25,
    rowsCount: 0,
    fullRowsCount: 0
  });
  const notificationsRef = React.useRef([]);
  const firstLoad = React.useRef(true);
  const clearFilter = React.useRef(false);

  const { id } = params;

  const workflowstatusApi = new WorkflowStatusApi();
  const workflowApi = new WorkflowApi();
  const workflowDataApi = new WorkflowDataApi();
  const dashboardApi = new DashboardReportApi();
  const plantApi = new PlantApi();
  const { check } = new CheckResponse();
  const { colors } = React.useContext(ColorsContext);
  const ability = useAbility(AbilityContext);

  const [filteredRequest, setFilteredRequest] = React.useState([]);
  const [finalDate, setFinalDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [initialDate, setInitialDate] = React.useState(
    moment(finalDate).subtract(1, "months").format("YYYY-MM-DD")
  );

  // const status standard's
  const [standardsSelects, setStandardsSelects] = useState([]);
  // const status defined
  const [filterStatus, setFilterStatus] = useState([]);
  const [filterPlants, setFilterPlants] = useState([]);

  const [data, setData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [workflow, setWorkflow] = useState({});
  const [loading, setLoading] = useState(false);
  const [highlightLast, setHighlightLast] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [relations, setRelations] = useState([]);
  const [formColumns, setFormColumns] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [hideFlowBtn, setHideFlowBtn] = useState(false);
  const [hideBtnCancel, setHideBtnCancel] = useState(false);
  const [openFilterWorkflowModal, setOpenFilterWorkflowModal] = useState(false);
  const [notificationsModal, setNotificationsModal] = useState({
    status: false,
    notifications: [],
    instance: {}
  });
  const [dialog, setDialog] = useState({
    title: "",
    message: "",
    type: null,
  });

  // Instance state
  const [openInstanceStateModal, setOpenInstanceStateModal] = useState({
    status: false,
    action: null,
    isDelete: false,
  });
  // Plot Plan modal
  const [plotPlanModal, setPlotPlanModal] = useState(false);
  const [plotPlanData, setPlotPlanData] = useState({});
  const [reloadFilters, setReloadFilters] = useState(false);

  const [filters, setFilters] = useState({
    workflowId: null,
    startDate: null,
    endDate: null,
    onlyActive: false,
    includeCanceled: false,
    includeClosed: false,
    status: [],
    plants: [],
    company: [],
    filter: undefined
  });

  const MINUTE_MS = 31000;

  // First api call
  useEffect(() => {
    async function call() {
      setLoading(true);
      await loadWorkflow(params.id);
      await loadPlantsFilters();
      await loadCompanyFilters();
      await loadStandardFilter(params.id);
      await handleClearFilters(true);
      setLoading(false);
    }
    call()
  }, [params.id])

  useEffect(() => {
    compareNotificationsWithTable(props.updateWorkflow);
  }, [props.updateWorkflow]);

  useEffect(() => {
    let intervalId;

    const loadDataWithInterval = async () => {
      try {
        const response = await loadData({showLoading: true});
        if (response.hasData) clearInterval(intervalId);
      } catch (error) {
        console.error(error);
      }
    };

    intervalId = setInterval(loadDataWithInterval, MINUTE_MS);

    const loadWorkflowsFiltersWithCancel = async () => {
      if (ability.can("WorkflowStatusList")) {
        let response = await workflowstatusApi.listAllByWorkflow(id);
        if (response.status === 200) {
          setFilterStatus(response.data);
          clearInterval(intervalId);
        }
      }
    };

    loadWorkflowsFiltersWithCancel();

    return () => clearInterval(intervalId);
  }, [params]);

  function clearData(isChangeWorkflow) {
    setFilteredRequest([]);
    setData([]);
    setRelations([]);
    firstLoad.current = true;
    let pageSize = isChangeWorkflow === true ? 25 : pageRef.current.pageSize
    pageRef.current = {page: 0, pageSize: pageSize, rowsCount: 0, fullRowsCount: 0};
    setFinalDate("");
    setInitialDate("");

    let clearedFilters = {
      workflowId: params.id,
      startDate: null,
      endDate: null,
      onlyActive: false,
      includeCanceled: false,
      includeClosed: false,
      status: [],
      plants: [],
      company: [],
      filter: undefined,
    }

    setFilters(clearedFilters)
    return clearedFilters
  }

  async function handleClearFilters(isChangeWorkflow) {
    let clearedFilters = clearData(isChangeWorkflow);
    clearFilter.current = !clearFilter.current;
    await loadData({...clearedFilters});
  }

  function compareNotificationsWithTable(notifications) {
    let tableData = [...data]
    if (notifications?.length > 0 && tableData?.length > 0) {
      tableData.map((row) => {
        row = {...row, ...assignNotificationToRow(row, notifications)}
      })
    }
    setData(tableData);
    setReloadFilters(!reloadFilters)
  }

  function assignNotificationToRow(tableRow, notifications) {
    notifications.map((obj) => {
      if (
        tableRow.id === obj.workflowDataId &&
        tableRow.workflowStatus?.notificationMessage !== obj.workflowData?.workflowStatus?.title &&
        tableRow.workflowData?.workflowStatus !== null) {
        tableRow.workflowStatus = obj.workflowData.workflowStatus;
        tableRow.notificationId = obj.id
        tableRow.type = obj.type
        tableRow.isApprove = obj.type === 'check_form' || obj.type === 'check_form_credentials'
        tableRow.nextStatus = obj.nextStatus
        tableRow.rejectStatus = obj.rejectStatus
      }
    })
    return tableRow
  }

  const chunk = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
      arr.slice(i * size, i * size + size)
  );

  async function loadData({startDate, endDate, includeCanceled, includeClosed, status, plants, company, filterText, showLoading}) {
    if (!showLoading) {
      setLoading(true);
    }

    let response = await workflowDataApi.workflowInstancesPaginated({
      workflowId: params.id || filters.workflowId,
      startDate: returnValue(startDate, filters.startDate),
      endDate: returnValue(endDate, filters.endDate),
      includeCanceled: returnValue(includeCanceled, filters.includeCanceled),
      includeClosed: returnValue(includeClosed, filters.includeClosed),
      statusId: returnValue(status, filters.status?.map(el => el.id)),
      plantId: returnValue(plants, filters.plants?.map(el => el.id)),
      companyId: returnValue(company, filters.company?.map(el => el.id)),
      filter: returnValue(filterText, filters.filter),
      currentPage: pageRef.current.page + 1,
      pageSize: pageRef.current.pageSize,
      sort: pageRef.current.sort,
      order: pageRef.current.order,
      onlyActive: filters?.onlyActive,
    });

    if (response?.status === 200) {
      let data = response.data;
      // Llamar una sola vez el endpoint de notificaciones
      if (!props.updateWorkflow && !notificationsRef.current?.length) {
        notificationsRef.current = await getNotifications();
      }
      else notificationsRef.current = props.updateWorkflow;

      pageRef.current.rowsCount = data.rowsCount;
      pageRef.current.fullRowsCount = data.rowsCount;
      let arrayData = await formatResponse(data)
      setLoading(false);
      firstLoad.current = false;

      return { hasData: true, data: arrayData };
    }

    setLoading(false);
    return { hasData: false, data: [] };
  }

  const returnValue = (value1, value2) => {
    return value1 !== undefined ? value1 : value2
  }

  async function formatResponse(currentData) {
    // Encontrar notificación correspondiente a instancia
    let workflowDataNotification = await findNotificationTable(currentData.results || [], notificationsRef.current);
    // Setear columnas dinámicas de cada instancia
    let mergedData = mergeDynamicColumns(workflowDataNotification);
    let arrayData = [];

    if (data.length === 0 || firstLoad.current) {
      arrayData = mergedData;
    }
    else {
      // Dividir filteredRequest en bloques de datos de "pageSize"
      let newArray = chunk(data, pageRef.current.pageSize);
      // Validar si la data de currentpage ya existe en filteredRequest
      // Si no existe se agrega la nueva data a filteredRequest
      if (newArray[currentData.currentPage - 1] === undefined) {
        arrayData = [...data, ...mergedData];
      }
      // Si ya existe se busca la posición y se reemplaza con la nueva data
      else {
        newArray[currentData.currentPage - 1] = mergedData;
        arrayData = [].concat(...newArray);
      }
    }

    if (firstLoad.current) {
      returnHeaders(arrayData);
    }

    setData(arrayData);
    setFilteredRequest(arrayData);
    return arrayData;
  }

  function mergeDynamicColumns(data) {
    const newData = data.map(item => {
      const newItem = {...item};

      item.formColumn?.reduce((acc, formColumn) => {
        acc[formColumn.title] = formColumn.value;
        return acc;
      }, newItem);

      return newItem;
    });

    return newData;
  }

  const returnHeaders = (data) => {
    let heads = [
      {
        id: "code",
        center: false,
        disablePadding: true,
        sortArrow: true,
        label: "workflow.table.id",
        sticky: true,
        direction: 'left',
        sortNamePaginated: 'code',
      },
      {
        id: "workflowStatus",
        center: false,
        disablePadding: true,
        sortArrow: true,
        format: (value) => value.workflowStatus?.title,
        label: "workflow.table.status",
        customSort: "workflowStatus?.title",
        sortNamePaginated: 'workflowStatus',
      },
      {
        id: "hasTitle",
        center: false,
        disablePadding: true,
        sortArrow: true,
        format: (value) => value.title,
        isOptional: true,
        label: "workflow.table.title",
        customSort: "title",
        sortNamePaginated: 'title',
      },
      {
        id: "plant",
        center: false,
        disablePadding: true,
        sortArrow: true,
        format: (value) => value?.plant?.name,
        label: "workflow.table.plant",
        customSort: "plant?.name",
        sortNamePaginated: 'plant',
      },
      {
        id: "hasValidity",
        center: false,
        disablePadding: true,
        sortArrow: true,
        format: (value) => {
          if (!value.validityFrom && !value.validityTo) {
            return null;
          }
          return (
            format({ date: value.validityFrom, formatWithoutHour: true }) +
            " - " +
            format({ date: value.validityTo, formatWithoutHour: true })
          );
        },
        isOptional: true,
        label: "workflow.table.validity",
        customSort: 'validityFrom',
        sortNamePaginated: 'validityFrom',
      },
      {
        id: "updatedAt",
        type: "date",
        center: false,
        disablePadding: true,
        format: (value) => format({ date: value.updatedAt, format: true }),
        sortArrow: true,
        orderDesc: true,
        label: "workflow.table.lastUpdate",
        sortNamePaginated: 'updatedAt',
      },
      {
        id: "relations",
        center: true,
        disablePadding: true,
        sortArrow: true,
        format: (value, index) => (value.relations[index] ? "Si" : "No"),
        label: "",
        hideColumn: true,
      },
      {
        id: "",
        center: false,
        disablePadding: true,
        sortArrow: false,
        label: "common.actions.title",
        hideColumn: true,
        sticky: true,
        direction: 'right',
      },
    ]

    data[0]?.formColumn?.map((obj, idx) => {
      let newObj = {
        id: obj.title,
        center: true,
        disablePadding: true,
        sortArrow: true,
        format: (value, index) => (value.formColumn[index] ? "Si" : "No"),
        label: obj.title,
        isOptional: true,
        sortNamePaginated: "D." + idx.toString().padStart(3, '0'),
        addHeader: true
      }
      heads.splice(heads.length - 1, 0, newObj)
    })

    if (ability.can("PlotPlanList") && ability.can("GetPlotPlan")) {
      heads.splice(heads.length - 1, 0, addPlotPlansColumn())
    }

    setHeaders(heads)
  }

  // data SpeedDial for dimension mobile
  const addDataSpeedDial = (row, workflow) => {
    const options = [
      {
        name: 'process',
        tooltip: t("common.actions.process"),
        onClick: () => handleOpenStatus(row, workflow),
        icon: <SlowMotionVideoIcon/>,
      },
      {
        name: 'duplicate',
        tooltip: t("common.actions.duplicate"),
        onClick: () => handleOpenDuplicate(row),
        icon: <ContentCopy/>,
        show: ability.can("CloneWorkflowData") ? true : false,
      },
      {
        name: 'cancel',
        tooltip: t("common.actions.cancel"),
        onClick: () => handleOpenDelete(row),
        icon: <Delete/>,
        show: ability.can("DeleteWorkflowData"),
        disabled: row.workflowStatus?.statusId === 10002 ||
        row.workflowStatus?.statusId === 10003 ||
        row.workflowData?.canCancel === false ||
        row?.canCancel === false,
      },
      {
        name: 'approve',
        tooltip: t('common.approve'),
        onClick: () => handleOpenApprove(true, row),
        icon: <CheckCircleIcon/>,
        show: ability.can("ApproveWorkflowData") || ability.can("ApproveWorkflowDataCredentials"),
        disabled: !row.isApprove,
      },
      {
        name:'reject',
        tooltip: t('common.reject'),
        onClick: () => handleOpenApprove(false, row),
        icon: <CancelIcon/>,
        show: ability.can("ApproveWorkflowData") || ability.can("ApproveWorkflowDataCredentials"),
        disabled: !row.isApprove,
      },
    ];

    return options
  };

  function addPlotPlansColumn() {
    return {
      id: "hasPlotPlan",
      center: true,
      disablePadding: true,
      sortArrow: false,
      isOptional: true,
      format: (value) => value.plotPlan?.name,
      label: "workflow.table.plot",
    },
    {
      id: "hasPlotPlanNotRequired",
      center: true,
      disablePadding: true,
      sortArrow: false,
      isOptional: true,
      format: (value) => (value?.plotPlans?.map((item) => item?.name) || []).join(', '),
      label: "workflow.table.plot",
    }
  }

  // Filtrado workflows states
  const loadPlantsFilters = async () => {
    if (ability.can("PlanUserList")) {
      let response = await plantApi.getByUser();
      if (response.status === 200) {
        setFilterPlants(response.data);
      }
    }
  };

  const loadCompanyFilters = async () => {
    let response = await dashboardApi.getPlantManagementCompany();
    if(response.status === 200) {
      setCompanyData(response.data);
    }
  }

  // Filtrado Standard
  const loadStandardFilter = async (id) => {
    if (ability.can("WorkflowStatusStandardList")) {
      const response = await workflowstatusApi.listFilterStandard(id);
      if (response.status === 200) {
        setStandardsSelects(response.data);
      }
    }
  };

  async function loadWorkflow(id) {
    let response = await workflowApi.detail(id);
    if (response.status === 200) {
      let data = response.data
      data?.formColumns?.reduce((acc, header) => {
        acc[header.title] = true
        return acc
      }, data)

      setWorkflow(data);
      setRelations(data?.relations);
      setFormColumns(data?.formColumns);
      addDinamicColumns(data?.formColumns);
    }
  }

  async function handleChangePage(page, pageSize, rowsCount, isFilter) {
    pageRef.current.page = page;
    pageRef.current.pageSize = pageSize || 25;
    pageRef.current.rowsCount = rowsCount || pageRef.current.fullRowsCount;
    if (!isFilter) return await loadData({});
    return []
  };

  async function handleFilters(e) {
    e?.preventDefault();
    firstLoad.current = true;
    await handleChangePage(0, pageRef.current.pageSize);
  }

  async function handleChangeFilters(e, name, type) {
    let value = ""
    if (type === "checkbox") value = e?.target?.checked
    else if (type === "date") {
      let date = moment(new Date(e))
      value = date.isValid() ? date.format("YYYY-MM-DD") : null
    }
    else value = e?.target?.value

    setFilters({...filters, [name]: value})
  }

  async function handleCreateWorkflow(users) {
    setLoading(true);

    users.nextStatusId = workflow.initialWorkflowStatusId;
    let data = { workflowId: params.id, users: users.users };
    const response = await workflowDataApi.create(data);
    let result = check(response);

    if (result.status) {
      handleClose();
      await loadData({});

      setDialog({
        title: t("workflow.messages.flowStarted.title"),
        message: t("workflow.messages.flowStarted.message", {
          workflow: workflow.name ? workflow.name : "",
        }),
      });
      setShowDialog(true);
    } else {
      toast.error(() => (
        <div>
          {t("workflow.messages.flowStarted.error")}
          <br />
          {result.errors}
        </div>
      ));
    }

    setLoading(false);
  }

  async function findDetailNotification(row) {
    let rowData = {...row}
    let isNotification = false;
    if (row.notificationId) {
      isNotification = true;
      rowData.id = row.notificationId
    }

    let notifications = await returnInstanceNotifications(rowData, isNotification, props.updateWorkflow);
    return notifications
  }

  async function handleOpenStatus(row, workflow) {
    setLoading(true);
    row.workflow = workflow;
    let notifications = await returnInstanceNotifications(row, false, props.updateWorkflow);
    setLoading(false);
    if (notifications?.length > 1) {
      setNotificationsModal({status: true, notifications: notifications, instance: row})
    }
    else {
      await redirectToAction(row, notifications, navigate, location)
    }
  }

  async function handleOpenApprove(value, row) {
    if (row.type === 'check_form' || row.type === 'check_form_credentials') {
      row.isMinimal = true
      let notifications = await findDetailNotification(row);
      let notificationStatus = {rejectStatus: row.rejectStatus, nextStatus: row.nextStatus,};
      if (notifications?.length > 0) {
        notificationStatus = {
          cancellationReasons: notifications[0].workflowStatus?.cancellationReasons || [],
          rejectStatus: notifications[0].rejectStatus,
          nextStatus: notifications[0].nextStatus
        }
      }
      let rowData = {...row,
        showApproveForm: true,
        approveInstance: value,
        rejectStatus: notificationStatus.rejectStatus,
        nextStatus: notificationStatus.nextStatus,
        workflowStatus: {...row.workflowStatus,
          cancellationReasons: notificationStatus.cancellationReasons
        }
      }
      setDialog({ row: rowData });
      showInstanceState(handleApprove, false, false, false, true, rowData)
    }
  }

  async function handleOpenDuplicate(row) {
    setDialog({ row: row });
    showInstanceState(handleDuplicate, false, false, true, row);
  }

  async function handleDuplicate(users) {
    setLoading(true);
    let sendData = {
      workflowDataId: users.users.nextStatusId,
      users: {
        notificationTo: users.users.notificationTo,
      },
    };
    let response = await workflowDataApi.clone(sendData);
    let result = check(response);

    if (result.status) {
      handleClose();
      await loadData({});

      setDialog({
        title: t("workflow.messages.flowDuplicated.title"),
        message: t("workflow.messages.flowDuplicated.message", {
          workflow: workflow.name ? workflow.name : "",
        }),
      });
      setShowDialog(true);
    } else {
      toast.error(() => (
        <div>
          {t("workflow.messages.flowDuplicated.error")}
          <br />
          {result.errors}
        </div>
      ));
    }
    setLoading(false);
  }

  function handleOpenDelete(row) {
    setDialog({
      title: t("workflow.messages.deleteFlow.confirm"),
      message: t("workflow.messages.deleteFlow.confirmMessage", {
        workflow: row.code,
      }),
      type: "confirmation",
      row: row,
    });
    setShowDialog(true);
  }

  async function handleDelete(users) {
    setLoading(true);

    let sendData = {
      id: dialog.row?.id,
      notificationId: dialog.row?.notificationId,
      statusId: dialog.row?.workflowStatus?.statusId,
      users: users.users,
			cancelObservations: users.cancelObservations
    };
    let response = await workflowDataApi.delete(sendData);
    let result = check(response);

    if (result.status) {
      handleClose();
      await loadData({});

      // Actualizar icono y estado temporalmente al momento de eliminar (desde back se tarda unos segundos)
      let array = [...data];
      array.map((obj) => {
        if (obj.id === dialog.row?.id)
          obj.workflowStatus = {
            ...obj.workflowStatus,
            title: t("workflow.messages.canceled"),
            color: "#F44E5B",
            icon: "close",
          };
      });
      setData(array);
      setDialog({
        title: t("workflow.messages.deleteFlow.title"),
        message: t("workflow.messages.deleteFlow.message"),
        type: null,
      });
      setShowDialog(true);
    } else {
      toast.error(() => (
        <div>
          {t("workflow.messages.deleteFlow.error")}
          <br />
          {result.errors}
        </div>
      ));
    }

    setLoading(false);
  }

  async function handleCloseDialog(value) {
    setShowDialog(false);
    if (value) {
      showInstanceState(handleDelete, true);
    }

    if (
      !dialog.row?.id &&
      data.length > 0 &&
      dialog.title === "Flujo iniciado exitosamente"
    ) {
      setHighlightLast(true);
      setTimeout(() => {
        setHighlightLast(false);
      }, 2000);
    }
  }

  function handleCloseNotifications() {
    setNotificationsModal({ status: false, notifications: [], instance: {} });
  }

  async function handleClose() {
    setOpenInstanceStateModal({ status: false });
    setDialog({
        title: "",
        message: "",
        type: null,
    })
  }

  async function handleApprove(formData) {
    let data = {
      id: formData.extraData?.id,
			approved: formData.extraData?.approveInstance,
			notificationId: formData.extraData?.notificationId,
			users: formData.users,
      cancellationReasonId: formData.cancellationReasonId,
			observations: formData.observations
		}

    setLoading(true);
    let response = null;
    if (formData.extraData?.type === "check_form_credentials") {
      data = { ...data, credentials: formData.credentials };
      response = await workflowDataApi.checkFormCredentials(data);
    } else {
      response = await workflowDataApi.approveForm(data);
    }

    let result = check(response);

    if (result.status) {
      handleClose();
      await loadData({});
      setDialog({
        title: t("forms.dynamicForm.messages.approveForm.title"),
        message: t("forms.dynamicForm.messages.approveForm.message", {
          value: formData.extraData?.approveInstance
            ? "forms.dynamicForm.approved"
            : "forms.dynamicForm.rejected",
        }),
      });
      setShowDialog(true);
    } else {
      toast.error(() => (
        <div>
          {t("forms.dynamicForm.messages.approveForm.error")}
          <br />
          {result.errors}
        </div>
      ));
    }
    setLoading(false);
  }

  async function showInstanceState(
    action,
    isDelete,
    isCreate,
    isDuplicate,
    isFashApprove,
    data
  ) {

    setOpenInstanceStateModal({
      status: true,
      action: action,
      extraData: data,
      isDelete: isDelete,
      isCreate: isCreate,
      isDuplicate: isDuplicate,
      isFashApprove: isFashApprove,
    });
  };

  const addDinamicColumns = ( dinacWorkflow  ) => {

    const addNewColumns = dinacWorkflow.map((el, index) => ({
      id: el.title.trim() + index,
      label: el.title,
      center: true,
      disablePadding: true,
      sortArrow: true,
      customSort: (item) => item.formColumn[index]?.value
    }))

    if(addNewColumns.length > 0) {
      headers.splice( -3, 0, ...addNewColumns )
    }

  }

  const onDownload = async() => {
    setLoading(true)
    console.log("filters.company ", filters.company)
    let response = await workflowDataApi.download({
      workflowId: params.id,
      startDate: filters.startDate,
      endDate: filters.endDate,
      includeCanceled: filters.includeCanceled,
      includeClosed: filters.includeClosed,
      statusId: filters.status?.map(el => el.id),
      plantId: filters.plants?.map(el => el.id),
      filter: filters.filter,
      companyId: filters.company?.map(el => el.id),
    });
    let result = check(response)

    if (result.status) {
      const fileName = workflow?.name || t("workflow.ValidationFile");
      ExportExcel({ apiData: response.data, fileName: fileName, isBlob: true });
    }
    else {
      toast.error(() =>
        <div>{t('common.errors.download')}<br/>
          {result.errors}
        </div>
      );
    }
    setLoading(false)
  }

  function openPlotPlan(row) {
    setPlotPlanData(row);
    setPlotPlanModal(true);
  }

  const validityTooltip = ( fromDate, toDate ) => {
    let validity;

    if( fromDate && toDate !== null) {

      validity = `${t('common.from')} ${format({ date: fromDate, formatWithoutHour: true })}
      ${t('common.to')} ${format({ date: toDate, formatWithoutHour: true })}
      `
      return validity

    }
  }

  const hideButtonInitInstance = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    let profileRoleId = profile?.Role.Id;
    const filterRoles = standardsSelects.filter(role => {
      return role.id === -1
    })

    if(filterRoles.length > 0){
      const isRoleMatch = filterRoles[0]?.roles.some(role => role.id === profileRoleId);
      setHideFlowBtn(isRoleMatch);
      return
    }
  }

  const hideButtonCancel = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    let profileRoleId = profile?.Role.Id;
    const filterRoleCancel = standardsSelects.filter(role => {
      return role.id === -3
    })

    if(filterRoleCancel.length > 0){
      const isRoleMatch = filterRoleCancel[0]?.roles.some(role => role.id === profileRoleId);
      setHideBtnCancel(isRoleMatch);
      return
    }
  }

  useEffect(() => {
    hideButtonInitInstance();
    hideButtonCancel();
  }, [params.id, standardsSelects])

  return (
    <Container open={props.open} mb={5}>
      <SectionPageTitle>
        <SectionPageLeft>
          <PageTitle
            title={workflow?.name}
            icon={
              <Icon baseClassName="material-icons-outlined">
                {workflow?.icon || ""}
              </Icon>
            }
          />
          <ContainerBarSearch>
            <SearchBarWorkflow
              handleChangeFilters={handleChangeFilters}
              handleFilters={handleFilters}
              clearFilter={clearFilter}
              workflow={params.id}
            />
          </ContainerBarSearch>
        </SectionPageLeft>

        <ContainersButtonsGroup twoContainers={true}>
          <ContainerButtonDownload>
            <Button
              variant="contained"
              endIcon={<CloudDownloadOutlined />}
              sx={{
                height: "100%",
                width: "100%",
                padding: "0 20px",
                backgroundColor: colors.buttonActionColor,
                color: colors.white,
                "&:hover": {
                  backgroundColor: colors.buttonActionColor,
                  opacity: 0.9,
                  color: colors.white,
                },
              }}
              aria-label="download"
              onClick={onDownload}
            >
              {t("common.download")}
            </Button>
          </ContainerButtonDownload>

          <Tooltip title={t("forms.formManagement.createNewForm")}>
            <IconAction
              size="medium"
              onClick={() =>
                showInstanceState(handleCreateWorkflow, false, true)
              }
            >
              <NewIcon />
            </IconAction>
          </Tooltip>
        </ContainersButtonsGroup>
      </SectionPageTitle>

      <TableWorkflow
        isCustom={true}
        loading={loading}
        workflow={workflow}
        rows={data}
        filteredRequest={filteredRequest}
        setFilteredRequest={setFilteredRequest}
        relations={relations}
        formColumns={formColumns}
        headers={headers}
        filterStatus={filterStatus}
        filterCompany={companyData}
        filterPlants={filterPlants}
        standardsSelects={standardsSelects}
        highlightLast={highlightLast}
        hideFlowBtn={hideFlowBtn}
        hideBtnCancel={hideBtnCancel}
        initialDate={initialDate}
        finalDate={finalDate}
        pageRef={pageRef}
        rowsCount={pageRef.current.rowsCount}
        currentPage={pageRef.current.page}
        pageSize={pageRef.current.pageSize}

        filters={filters}
        handleFilters={handleFilters}
        handleChangeFilters={handleChangeFilters}
        handleClearFilters={handleClearFilters}

        onChangePage={handleChangePage}
        validityTooltip={validityTooltip}
        addDataSpeedDial={addDataSpeedDial}
        openPlotPlan={openPlotPlan}
        handleCreateWorkflow={ability.can("CreateWorkflowData") ? () => showInstanceState(handleCreateWorkflow, false, true) : null}
        handleOpenStatus={handleOpenStatus}
        handleApprove={handleOpenApprove}
        handleOpenDuplicate={handleOpenDuplicate}
        handleOpenDelete={handleOpenDelete}
        setOpenFilterWorkflowModal={setOpenFilterWorkflowModal}
        section={'workflow'}
        loadData={loadData}
      />
      <BackdropLoading open={loading} />
      <ShowDialog
        openDialog={showDialog}
        dialog={dialog}
        handleCloseDialog={handleCloseDialog}
      />

      <Modal open={openInstanceStateModal.status}>
        <BoxModal>
          <InstanceStateModalCard
            data={dialog?.row || workflow}
            extraData={openInstanceStateModal.extraData}
            isCreateInstance={openInstanceStateModal.isCreate}
            isDelete={openInstanceStateModal.isDelete}
            isDuplicate={openInstanceStateModal.isDuplicate}
            isFashApprove={openInstanceStateModal.isFashApprove}
            handleSave={openInstanceStateModal.action}
            handleClose={handleClose}
          />
        </BoxModal>
      </Modal>

      <Modal open={openFilterWorkflowModal}>
        <BoxModal>
          <FilterWorkflowsModalCard
            filterStatus={filterStatus}
            filterPlants={filterPlants}
            standardsSelects={standardsSelects}
            initialDate={initialDate}
            finalDate={finalDate}

            filters={filters}
            handleFilters={handleFilters}
            handleChangeFilters={handleChangeFilters}
            handleClearFilters={handleClearFilters}
            setOpenFilterWorkflowModal={setOpenFilterWorkflowModal}
          />
        </BoxModal>
      </Modal>

      <Modal open={notificationsModal.status}>
        <BoxModal>
          <InstanceNotificationsModal
            notifications={notificationsModal.notifications}
            instance={notificationsModal.instance}
            handleClose={handleCloseNotifications}
          />
        </BoxModal>
      </Modal>

      <PlotPlanView
        data={plotPlanData}
        openDialog={plotPlanModal}
        handleCloseDialog={() => setPlotPlanModal(false)}
      />
    </Container>
  );
};
